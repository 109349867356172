// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup {
    position: fixed;
    top: 50%;
    width: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .popup textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    resize: none;
  }
  
  .popup button {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 8px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    
  }
  
  .popup button:hover {
    background: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Popup/ReviewPopup.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,UAAU;IACV,SAAS;IACT,gCAAgC;IAChC,gBAAgB;IAChB,aAAa;IACb,wCAAwC;IACxC,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,eAAe;;EAEjB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".popup {\n    position: fixed;\n    top: 50%;\n    width: 40%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background: #fff;\n    padding: 20px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    border-radius: 8px;\n  }\n  \n  .popup textarea {\n    width: 100%;\n    height: 100px;\n    margin-bottom: 10px;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    font-size: 16px;\n    resize: none;\n  }\n  \n  .popup button {\n    background: #007bff;\n    color: #fff;\n    border: none;\n    padding: 8px 20px;\n    font-size: 16px;\n    border-radius: 4px;\n    cursor: pointer;\n    \n  }\n  \n  .popup button:hover {\n    background: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
