import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
function Home() {
    const navigate = useNavigate();
    useEffect(() => {
      navigate("/home");
    }, []);
  
   
  return (
    <div>
        <p>Loading</p>
    </div>
  )
}

export default Home