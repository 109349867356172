import React, { useEffect, useRef } from 'react'
import  { useState } from 'react'
import "./Form.css"
import {useNavigate,Link} from "react-router-dom"
import axios from 'axios';
import { toast } from 'react-toastify';  
import moment from 'moment/moment';
import { Button } from 'evergreen-ui';
import { jwtDecode } from "jwt-decode";
import Select from 'react-select';
 
const initialState ={
  projectId: '',
  streamId:'',
  bunitId:"",
  projectName:'',
  description:'',
  rag:'',
  dependency:'',
  createdAt:'',
  status:'Pending'
};
function Form() {
  const[projectList,setProjectList]=useState(initialState);
  const {projectId,streamId,bunitId, projectName, description, rag, dependency,createdAt,status} = projectList;
  const arrLen = (projectList.length) === undefined ? 1 : projectList.length;
  const initialArray = Array.from({ length: arrLen}, () => ({  projectId:'' ,projectStage:'' ,projectStatus:'' ,percentCompleted:'' ,targetDate:'' ,remarks: '' }));
  const [stagesArray,setStagesArray] = useState(initialArray);
  const [projects,setProjects]=useState([]);
  const latestProjectId = useRef(0);
  const [proId,setProId]=useState('');
  const [projectItems, setProjectItems] = useState([]);
  const [formData, setFormData]= useState([]);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [currentWeek,setCurrentWeek] = useState(false);
  const token = localStorage.getItem("token");
  const [menuItems, setMenuItems] = useState([]);
  const [userId, setUserId] = useState();
  const [streamList,setStreamList] = useState([]);
  const [buList,setBuList]=useState([])
  const [isPopupVisible, setPopupVisible] = useState(false);
  const navigate = useNavigate();
  const [isPopVisible, setPopVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [newSuggestions, setNewSuggestions] = useState([]);
  const [deleteIndex,setDelIndex] = useState('');
  var id = null;
  useEffect(()=>{
    if (token) {
      const decodedToken = jwtDecode(token);
       id = decodedToken.id;
      setUserId(id);
    }
   fetchMenuItems(id);
   buNames();
   getStagesList();
  },[token])
 
const getStagesList = async() =>{
  try{
    const response = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/getStagesList`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProjectItems([...response.data]);
  }
  catch(error){
    console.error('Error fetching suggestions:',error);
  }
}
  const handleSuggestions = async (e) => {
    const {name, value} = e.target;
    setProjectList({ ...projectList, [name]: value });
    setInputValue(value);
    const search = {
      query : value,
      streamId: streamId
    };
    try{
      const response = await axios.post(`${process.env.REACT_APP_DOMAIN}/api/getSuggestions`,search,{
        headers: {
          'Authorization': `Bearer ${token}`
      }
    });
      setSuggestions(response.data);
      setNewSuggestions(response.data)
    }
    catch(error){
      console.error('Error fetching suggestions:',error);
    }
 
  };
 
  const handleSelectSuggestion = (suggestion) =>{
    setInputValue(suggestion);
    fetchFormData(suggestion);
    setSuggestions([]);
  };
 
  const fetchFormData = async(inputValue)=>{
    try{
      const projectNameObj = {
        projectName : inputValue
      };
      const response = await axios.post(`${process.env.REACT_APP_DOMAIN}/api/getProjectListByName`,projectNameObj,{
        headers: {
          'Authorization': `Bearer ${token}`
      }
    });
      const projectData = response.data;
      setFormData(projectData);
        setProId(response.data[0].projectId);
         setProjectList({ ...projectList,bunitId:response.data[0].bunitId,projectName: response.data[0].projectName, description: response.data[0].description, rag: response.data[0].rag,  dependency: response.data[0].dependency});
         const modifiedArray  = response.data[0].projectStagesEntity.map(({stagesMasterEntity, ...rest})=> rest);
         setStagesArray({...stagesArray,stagesArray:modifiedArray});
         setStagesArray(modifiedArray);
          const created=moment(response.data[0].createdAt);
          setCurrentWeek(created.isBetween(moment().startOf('week'), moment().endOf('week'), 'day','[]'))
    }catch(error){
      console.error('Error fetching data:', error);
    }
  };
 
 
  const getLatestProjectDetails = async (e)=>{
   
    try{
      const response = await axios.get(`${process.env.REACT_APP_DOMAIN}/api/getLatestProjectDetails`,{
        headers: {
          'Authorization': `Bearer ${token}`
      }
    });
      setProjects(response.data);
      if(response.data.length === 0){
        latestProjectId.current = 1;
      }
      else{
      latestProjectId.current = response.data[0].projectId + 1;
      }
 
     currentWeek ? ( updateProjectDetails(e) ) : ( saveProjectId(e) )
    }
    catch(error){
      console.error('Error fetching data',error);
    }
  };
 
  const saveProjectId=(e)=>{
      const projectId = latestProjectId.current;
 
      stagesArray.forEach((stage) => {
        stage.projectId = projectId;
       
      });
 
      projectList.projectId = projectId;
      stagesArray.forEach((stage)=>{
        stage.id = '';
      })
     
 
      saveProjectDetails(e);
     
  };
 
  const updateProjectDetails = async(e) => {
    e.preventDefault();
    projectList.projectId=proId;
    stagesArray.forEach((stage)=>{
      stage.projectId = proId;
    })
    const updateDetails = {
      ...projectList,
      projectStagesEntity: stagesArray
    };
    try{
     await axios.put(`${process.env.REACT_APP_DOMAIN}/api/updateProjectList/${proId}`,updateDetails,{
        headers: {
          'Authorization': `Bearer ${token}`
      }
    });
     
    }catch(error){
      console.error('Error updating',error);
    }
    setTimeout(() => navigate("/"), 500);
    toast.success("Updated Successfully");
  }
  
 
  const addStage = () => {
    setStagesArray([...stagesArray, { projectStatus: '', percentCompleted: '', targetDate: '', remarks: '' }]);
  };
 
  const removeStage = (index) => {
    const newStagesArray = [...stagesArray];
    newStagesArray.splice(index, 1);
    setStagesArray(newStagesArray);
  };
 
 
  const submitProjectDetails = (e) => {
    e.preventDefault();
    let date= new Date();
    let currDate= moment(date).format("YYYY-MM-DD");  
    projectList.createdAt=currDate;
    getLatestProjectDetails(e);
   
  };
 
  const saveProjectDetails = (e) => {
    e.preventDefault();
    const addProjectDetails = {
      projectListEntity: projectList,
      projectStagesEntities: stagesArray,
    };
    axios.post(`${process.env.REACT_APP_DOMAIN}/api/addProjectStatus`,addProjectDetails,{
      headers: {
        'Authorization': `Bearer ${token}`
    }
  })
    .then((data) => {
      if(data.status === 200){
      }
    })
    .catch((error)=>{
      console.error(error);
    });
    setTimeout(() => navigate("/"), 500);
    toast.success("Project Added Successfully");
   
   
  }
  const deleteProject = () =>{
    try{
      axios.delete(`${process.env.REACT_APP_DOMAIN}/api/deleteProject/${proId}`,{
        headers: {
          'Authorization': `Bearer ${token}`
      }
    });
      console.log("success");
    }catch(error){
      console.error('Error deleting',error);
    }
    setTimeout(() => navigate("/"), 300);
    toast.success("Deleted Successfully");
  };
  const handleDelete = () => {
    deleteProject();
 
    setPopupVisible(false);
  };
 
  const addStatus = (e,index) => {
 
    let newArr =[...stagesArray];
    newArr[index].projectStatus= e.target.value;
    setStagesArray(newArr);
  };
  const  handleTargetDate = (e, index) => {
 
    const updateArray = [...stagesArray];
    updateArray[index] = {...updateArray[index], targetDate: e.target.value};
    setStagesArray(updateArray);
 
  }
 
 
  const handleRemarks = (e,index) => {
    const updateArray = [...stagesArray];
    updateArray[index] = {...updateArray[index], remarks: e.target.value};
    setStagesArray(updateArray);
  }
 
 const handleProjectStage = (selectedOption, index) => {
 
  const selectedStageId = selectedOption.value;
 
  const updatedStagesArray = [...stagesArray];
  updatedStagesArray[index] = {
    ...updatedStagesArray[index],
    stageId: selectedStageId,
  };
  setStagesArray(updatedStagesArray);
};
const addPercentage = (e, index) => {
const updateArray = [...stagesArray];
updateArray[index] = {...updateArray[index], percentCompleted: e.target.value};
setStagesArray(updateArray);
}
  const addProjectList = (e) => {
    const { name, value } = e.target;
    setProjectList({ ...projectList, [name]: value });
  };
  const fetchMenuItems = (id) => {  
       axios.get(
        `${process.env.REACT_APP_DOMAIN}/api/userStreams/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((resp)=>{
        setMenuItems([...resp.data]);
        const menu = [...resp.data];
        streamNames(menu);
      }).catch((error) => {
        console.error('Error:', error);
      });
  };
  const buNames = async() =>{
    try {
      
      const response = await axios.get(
        `${process.env.REACT_APP_DOMAIN}/api/getBUnitList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
  
      setBuList(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
   }
 const streamNames = async(menu) =>{
  try {
     
    const response = await axios.get(
      `${process.env.REACT_APP_DOMAIN}/api/getStreamList`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const elements = response.data;
    const filteredList = elements.filter(element => menu.includes(element.id));
    setStreamList(filteredList);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
 }
 const handleDel = () => {
  handleDeleteStageField();
 
  setPopVisible(false);
}
const handlePop = (id,index) =>{
     
  setPopVisible(true);    
  setDeleteId(id);
  setDelIndex(index);
 
}
 
const handleDeleteStageField = async () => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_DOMAIN}/api/deleteStageField/${deleteId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
  } catch (error) {
    console.error('Error while deleting stage', error);
  }

  const newStagesArray = [...stagesArray];
    newStagesArray.splice(deleteIndex, 1);
    setStagesArray(newStagesArray);


};
 
 
const options = projectItems.map(item => ({
  value: item.id,
  label: item.stages,
}));
 
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width:'200px',
    border:'none',
    boxShadow: state.isFocused ? 'white' : null,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#4c9aff' : null,
    color: state.isSelected ? 'white' : null,            
  }),
 
};
 
  return (
        <div className='form-main'>
 
      { currentWeek ? (
        <button className='delete' onClick={()=>setPopupVisible(true)}>Delete</button> ):
        ( "" )
        }
{isPopupVisible && (
  <div className="del-popup">
    <p>Are you sure you want to delete?</p>
    <button onClick={()=>handleDelete()}>Yes</button>
    <button onClick={() => setPopupVisible(false)}>No</button>
  </div>
)}
{isPopVisible && (
  <div className="del-popup">
    <p>Are you sure you want to delete?</p>
    <button onClick={()=>handleDel()}>Yes</button>
    <button onClick={() => setPopVisible(false)}>No</button>
  </div>
)}
        <form style={{
            margin:"auto",
            padding:"15px",
            maxWidth:"800px",
            alignContent:"center"
        }} onSubmit={submitProjectDetails}>
         <Link to="/">
          <input type='button' value="&#x3c; Back"/>
         </Link>      
            <h4>Enter the Project Details</h4>
            <label >Project Type :</label>
            <select className='status' name="streamId" onChange={addProjectList} required>
            <option value="" disabled selected hidden>--Select Project Type--</option>    
              {streamList.map((item, index) => (
        <option key={index} value={item.id}>
          {item.streamName}
        </option>
      ))}
      </select>
     
            <label htmlFor='projectName'>Project Name : </label>
            <input
            type='text'
            id='projectName'
            name='projectName'
            placeholder='Enter The Project Name'
            value={inputValue}
            onChange={handleSuggestions}
            required
            />
            <ul>
              {suggestions.map((suggestion, index)=>(
                <li key={index} onClick={() => handleSelectSuggestion(suggestion)}>
                  {suggestion}
                </li>
              ))}
            </ul>
            <label >Business Unit :</label>
            <select className='status' name="bunitId" value={bunitId} onChange={addProjectList} required>
            <option value="" disabled selected hidden>--Select Business Unit--</option>    
              {buList.map((item, index) => (
        <option key={index} value={item.id}>
          {item.bunit}
        </option>
      ))}
            </select>
            <label >Description : </label>
            <textarea placeholder='Enter the Description' name='description' value={description} onChange={addProjectList} style={{resize:"none"}} />
            <label >RAG :</label>
            <select className='status' name="rag" value={rag} onChange={addProjectList} required>
            <option value="" disabled selected hidden>--Select Project Type--</option>
              <option value="red" style={{color:'red'}}>Red </option>    
              <option value="amber" style={{color:'orange'}}>Amber</option>
              <option value="green" style={{color:'green'}}>Green</option>
            </select>
            <div>
             <label htmlFor='Stages'>Stages : </label>
            {stagesArray.map((stage, index) => (              
        <div key={stage.id} className='stages-content'>
          <div className='stages-main'>
           
                <div>
      <label htmlFor="project">Stage</label>
        <Select
        className='stage'
        name="projectStages"
        value={options.find(option => option.value === (parseInt(stagesArray[index]?.stageId,10) || ''))}
        onChange={(selectedOption) => handleProjectStage(selectedOption, index)}
        options={options}
        placeholder="--Search Stages--"
        isSearchable={true}
        styles={customStyles}
      />
    </div>
               <div>
                <label>Status</label>
                <select className='status'  name="projectStatus" value={stagesArray[index]?.projectStatus || ''} onChange={(e) => addStatus(e, index)}  required>
            <option value="" disabled selected hidden>--Select Status--</option>
              <option>
              To be done
              </option>
              <option>
              Work in progress
              </option>
              <option>
             Completed
              </option>
            </select>
           
               </div>
               <div className='completed'>
                <label>% Completed</label>
                <input type="number" name='percentCompleted' value={stagesArray[index]?.percentCompleted || ''} onChange={(e)=>addPercentage(e, index)} max={100} required></input>
               
               </div>
               <div className='target'>
                <label>Target Date</label>
                <input type="date" value={stagesArray[index]?.targetDate || ''} onChange={(e)=>handleTargetDate(e, index)}></input>
               </div>
                  
            </div>
            <div className='stage-remark'>
           
              <div className='remarks'>
             
                <input type="text" name='remarks' value={stagesArray[index]?.remarks || ''} onChange={(e)=>handleRemarks(e, index)} placeholder='Remarks'></input>
               </div>
               
               {stage.id !== undefined  && currentWeek ? (
            <div>
            <Button variant="contained" type="button" style={{ backgroundColor: 'red', color: 'white',marginTop:"12px" }} onClick={()=>handlePop(stage.id,index)}>
                Delete
              </Button>
              </div>
            ) : ""}
 
               {(!currentWeek && index >= initialArray.length) || (currentWeek && (stage.id == undefined))? (
              <Button onClick={() => removeStage(index)} className='remove-btn' variant="contained" style={{ backgroundColor: 'red', color: 'white' }}>
                X
              </Button>
               
            ): null}
             </div >
            </div>
              ))}
             
       <Button  type="button"  onClick={()=>addStage()} className='add-btn' variant="contained"  style={{color:"white",backgroundColor:"#0084A7",marginBottom:"5px"}}>+Add More
         </Button>
      </div>
            <label htmlFor='dependency'>Dependency (if any)</label>
            <input
            type='text'
            id='dependency'
            name='dependency'
            placeholder='Enter the dependency'
            value={dependency}
            onChange={addProjectList}
            />
        <input type="submit" value={currentWeek ? 'Update' : 'Submit'}/>
        </form>
        </div>
   
  )
}
 
export default Form