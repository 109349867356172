// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination{
    margin-bottom: 10px;
    margin-left: 40%;
}
.pagination span{
    border: 1px solid white;
    padding:11px;
    width: 40px;
    background-color: rgba(173, 186, 192, 0.795);
    cursor: pointer;

}

.pagination span.active{
    background-color: rgb(99, 132, 148);
}

`, "",{"version":3,"sources":["webpack://./src/Components/Pagination/Pagination.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,uBAAuB;IACvB,YAAY;IACZ,WAAW;IACX,4CAA4C;IAC5C,eAAe;;AAEnB;;AAEA;IACI,mCAAmC;AACvC","sourcesContent":[".pagination{\n    margin-bottom: 10px;\n    margin-left: 40%;\n}\n.pagination span{\n    border: 1px solid white;\n    padding:11px;\n    width: 40px;\n    background-color: rgba(173, 186, 192, 0.795);\n    cursor: pointer;\n\n}\n\n.pagination span.active{\n    background-color: rgb(99, 132, 148);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
