// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-table-main{
    padding: 20px;
}
thead>th{
    background: #9BC2EE;
color: black;

}
.user-title{
    display: flex;
   justify-content: space-between;
   margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/UserList/UserList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,mBAAmB;AACvB,YAAY;;AAEZ;AACA;IACI,aAAa;GACd,8BAA8B;GAC9B,mBAAmB;AACtB","sourcesContent":[".user-table-main{\n    padding: 20px;\n}\nthead>th{\n    background: #9BC2EE;\ncolor: black;\n\n}\n.user-title{\n    display: flex;\n   justify-content: space-between;\n   margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
