import React, { useEffect, useState } from 'react'
import "./StageMaster.css"
import { Button } from 'antd'
import axios from 'axios';
import { Link} from 'react-router-dom';
import CustomPagination from '../../../Components/Pagination/CustomPagination';
import { toast } from 'react-toastify';
import { jwtDecode } from "jwt-decode";
function StageMaster() {
    const [stageData, setStageData] = useState([]);
    const token = localStorage.getItem("token");
    const [formData, setFormData] = useState([]);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = stageData.slice(startIndex, endIndex);
    const data = stageData.length;
    const [userRole, setUserRole] = useState(null);
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
    useEffect(() => {
      
    if (token) {
      const decodedToken = jwtDecode(token);
      const role = decodedToken.role;    
      setUserRole(role);         
    }

      displayStages();
      }, []);
      const handleChange = (e) => {
        const { value } = e.target;
        const stageName=value
        setFormData([
          stageName
        ]);
      };
      const resetFormData = () => {  
        setFormData([ ]);
      };
      const handlePop = (id) =>{
      
        setPopupVisible(true);     
        setDeleteId(id);
  
      }
      const displayStages =()=>{
        axios.get(`${process.env.REACT_APP_DOMAIN}/api/getStagesList`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },})
        .then(response => {
          setStageData(response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
       
            }
      const handleSubmit = (e) => {
        e.preventDefault();
        if(formData){
          axios.post(`${process.env.REACT_APP_DOMAIN}/api/addStagesMaster`,formData,{
            headers: {
                Authorization: `Bearer ${token}`,
              },

        })
          .then(response => {
           displayStages();
          resetFormData()
          toast.success("Stage Added Successfully");
          })
          
          .catch(error => {
            console.error('Error fetching form data:', error);
          });
          
        }
        else{
          alert("Stage Name cannot be empty");
        }
      };
      const handleDelete = async () => {
        try {
          await axios.put(`${process.env.REACT_APP_DOMAIN}/api/deleteStage/${deleteId}`,{}, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        displayStages()
        } catch (error) {
          console.error('Error updating deleteStatus:', error);
        }
        setPopupVisible(false);
      };
  return (
    <div className='stage-main'>
      <div>      
         <Link to="/home">
      <Button  variant="contained" style={{backgroundColor:"#0084A7",color:"white",height:"40px",marginBottom:"10px"}} >
      &#x3c; Back
      </Button>
      </Link>
      </div>

        <h5>Stage Master</h5>
        <div className='stage-input'>
        
      <input
        type='text'
        name="stages"
        className='input-stage'
        value={formData}
        placeholder='Add a Stages Name'
        style={{width:"300px"}}
        onChange={handleChange}
        required
      />
      <Button type="submit" variant="contained" style={{backgroundColor:"#0084A7",color:"white",height:"40px",marginTop:"7px"}} onClick={handleSubmit}>
        + Add Stage
      </Button>
     
  
        </div>
        
        <div className='st-table'>
        <table className="table table-striped stage-table" >
        <thead className="thead-dark">
        <th >S.no</th>
            <th >Stage Name</th>
            {userRole === "SUPERADMIN" ?
            <th>Action</th> : <th></th>
}
            </thead>
            <tbody>
            
        {currentData.filter(stage => stage.deleteStatus === false)
        .map((stage, index) => (
           
          <tr key={index}>
            <td className="counterCell"></td>
            <td>{stage.stages}</td>
            <td>
            
            {userRole === "SUPERADMIN" ? 
                  <Button
                    variant="contained"
                    onClick={() => handlePop(stage.id)}
                    style={{ backgroundColor: '#0084A7', color: 'white' }}
                  >
                    Delete
                  </Button> : ""
}
            
            </td>
          </tr>

        ))}
      </tbody>
            </table>
        </div>
        {isPopupVisible === true ? (
            <div className="del-popup">
              <p>Are you sure you want to delete?</p>
              <button onClick={handleDelete}>Yes</button>
              <button onClick={() => setPopupVisible(false)}>No</button>
            </div>
          ):""}
          <CustomPagination
           totalItems={data}
           itemsPerPage={itemsPerPage}
           onPageChange={handlePageChange}
          
          />

    </div>
  )
}

export default StageMaster