// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.head-main{
    height:60px;
    background: #0084A7;
 
    
   
}
.logo{
    width:110px;
    padding: 13px;
    
}
.title{
  position: relative;
  width: 100px;
  bottom: 50px;
  left: 46%;
  color: white;
  font-size: 16px;
  
  

}
`, "",{"version":3,"sources":["webpack://./src/Components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;;;;AAIvB;AACA;IACI,WAAW;IACX,aAAa;;AAEjB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,SAAS;EACT,YAAY;EACZ,eAAe;;;;AAIjB","sourcesContent":[".head-main{\n    height:60px;\n    background: #0084A7;\n \n    \n   \n}\n.logo{\n    width:110px;\n    padding: 13px;\n    \n}\n.title{\n  position: relative;\n  width: 100px;\n  bottom: 50px;\n  left: 46%;\n  color: white;\n  font-size: 16px;\n  \n  \n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
