import "react-toastify/dist/ReactToastify.css";
import aadService from "./AuthProvider";
export const handleAuthValidate = () => {
    const value = aadService.getValidate()
    switch (value?.stage) {
        case 1: return value
        case 2: return value;
        case 3: return value;
    }

}
export const setAuthSession = (res) => {
    sessionStorage.setItem('AccessToken', res.data.token)
    sessionStorage.setItem('role', res.data.user.role)
    sessionStorage.setItem('fullName', res.data.user.fullName)
}

