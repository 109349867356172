import React, { useEffect, useState } from 'react'
import "./UserList.css"
import axios from 'axios';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
function UserList() {
    const [userData, setUserData] = useState([]);
    const token = localStorage.getItem("token");
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_DOMAIN}/api/superAdmin/getUsersList`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },})
          .then(response => {
            setUserData(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);
  return (
    <div className='user-table-main'>
      <div className='user-title'>
        <h5>Users</h5>
        <Link to="/home">
      <Button  variant="contained" style={{backgroundColor:"#0084A7",color:"white",height:"30px"}} >
        Back
      </Button>
      </Link> 
     
        </div><hr></hr>
        <div>
        <table className="table table-striped">
        <thead className="thead-dark">
        <th>S.no</th>
            <th>User Name</th>
            <th>Email</th>
            <th>Action</th>
            </thead>
            <tbody>
        {userData.map((user, index) => (
          <tr key={index}>
            <td className="counterCell"></td>
            <td>{user.name}</td>
            <td>{user.email}</td>
            <td>
            <Link to={`/user-form/${user.id}`}>
              <Button  variant="contained" >Permission</Button>
            </Link>
            </td>
          </tr>
        ))}
      </tbody>
            </table>
        </div>

    </div>
  )
}

export default UserList