// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stage-main{
    padding: 15px;
}
.stage-input{
    display: flex;
    gap: 15px;
}
.input-stage{
    height:40px;
    
}
.stage-table{
    text-align: center;
}

`, "",{"version":3,"sources":["webpack://./src/Pages/Home/StageMaster/StageMaster.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,SAAS;AACb;AACA;IACI,WAAW;;AAEf;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".stage-main{\n    padding: 15px;\n}\n.stage-input{\n    display: flex;\n    gap: 15px;\n}\n.input-stage{\n    height:40px;\n    \n}\n.stage-table{\n    text-align: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
