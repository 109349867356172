// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #7983ff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-top: 10px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }`, "",{"version":3,"sources":["webpack://./src/Components/Loading/Loading.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,oCAAoC;IACpC,0BAA0B;IAC1B,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,kCAAkC;EACpC;;EAEA;IACE;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".loading-spinner {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n  }\n  \n  .spinner {\n    border: 4px solid rgba(0, 0, 0, 0.1);\n    border-left-color: #7983ff;\n    border-radius: 50%;\n    width: 50px;\n    height: 50px;\n    margin-top: 10px;\n    animation: spin 1s linear infinite;\n  }\n  \n  @keyframes spin {\n    to {\n      transform: rotate(360deg);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
