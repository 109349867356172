import React from 'react'
import "./Header.css"
import Logo from "../../Images/Logo-citpl.svg"
function Header() {
  return (
    <div className='head-main'>
      <div className='head'>
     <img className='logo' src={Logo} alt=''/> 
      </div>    
      <div className='title'><p>Project Status</p></div>
      
    </div>
  )
}

export default Header