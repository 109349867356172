
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { toast } from 'react-toastify';
import './App.css';
import aadService from './Auth/AuthProvider';
import { handleAuthValidate } from './Auth/AuthServices';
import Header from './Components/Header/Header';
import Form from './Pages/AddProjectForm/Form';
import ProjectList from './Pages/Home/ProjectList';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from "./Pages/Home/Home";
import UserPermission from "./Pages/UserPermission/UserPermission";
import UserList from "./Pages/UserList/UserList";
import StageMaster from "./Pages/Home/StageMaster/StageMaster";
import BuMaster from "./Pages/Bu/BuMaster";

function App() {
  const [idtoken, setIdtoken] = useState(sessionStorage.getItem('adal.idtoken'));
  const [isLogin, setIsLogin] = useState(false);
  const [validateMsg, setValidateMsg] = useState('');

  const fetchLogin = async () => {
  //   const token1 = localStorage.getItem("token");
  // console.log(token1);
    const getValidation = handleAuthValidate();
    // console.log(getValidation);
    setValidateMsg(getValidation);
    if (getValidation?.stage === 1) {
      if (sessionStorage.getItem('AccessToken')) {
        setIsLogin(true);
      }
      else {
        // const response = await axios.get(`http://localhost:8080/api/auth/login?token=${sessionStorage.getItem('adal.idtoken')}`);
        // setAuthSession(response);
        setIsLogin(true);
        // toast.success(`You have successfully authenticated with Microsoft Login.`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      }
    }
    else if (getValidation !== undefined) {
      aadService.login();
    }

  };

  useEffect(() => {
    fetchLogin();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
      <ToastContainer position='top-center'/>
    
    { isLogin?
    <div>
    <Header/> 
    {/* <UserPermission/> */}
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/home" element={<ProjectList/>}/>
      <Route path="/form" element={<Form/>}/>
      <Route path="/stageMaster" element={<StageMaster/>}/>
      <Route path="/BuMaster" element={<BuMaster/>}/>
      <Route path="/userPermission" element={<UserList/>}/>
      <Route path="/user-form/:id" element={<UserPermission/>}/>
    </Routes> 
    </div> 
     : ""} 
    </BrowserRouter>
    </div>
  );
}

export default App;
