import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';  
import './ReviewPopup.css';
const ReviewPopup = ({projectIds}) => {
  const [showPopup, setShowPopup] = useState(true);
  const [comment, setComment] = useState('');
  const token = localStorage.getItem("token");


  const handleInputChange = (e) => {
    setComment(e.target.value);
  };
  const handleSubmit = () => {
    
    const commentObj = {
        comments : comment,
        projectIds : projectIds
    }
    try{
       axios.put(`${process.env.REACT_APP_DOMAIN}/api/addComments`,commentObj,{
        headers: {
          'Authorization': `Bearer ${token}`
      }
      
    }); 
       
      }
      catch(error){
        console.error('Error fetching data',error);
      }
    setComment('');
    setShowPopup(false);
    toast.success("Rejected Successfully");
  };

 


  return (
    <div>
      
      {showPopup &&
        <div className="popup">
          <h6>Enter Comment * : </h6>
          <textarea value={comment} onChange={handleInputChange} />
          <button onClick={handleSubmit}>Submit</button>
        </div>
}
    </div>
  );
};

export default ReviewPopup;
