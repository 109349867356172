import { DatePicker } from "antd";
import axios from "axios";
import { addWeeks, endOfWeek, format, startOfWeek } from "date-fns";
import { StatusIndicator } from "evergreen-ui";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import CustomPagination from "../../Components/Pagination/CustomPagination";
import { toast } from "react-toastify";
import "./ProjectList.css";
import ReviewPopup from "../../Components/Popup/ReviewPopup";
import { jwtDecode } from "jwt-decode";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Export from "../../Images/download.svg";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";
function ProjectList() {
  const [projects, setProjects] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [projectType, setProjectType] = useState("");
  let itemsPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  //const startIndex = (currentPage - 1) * itemsPerPage;
  //const endIndex = startIndex + itemsPerPage;
  const [startIndex, setStartIndex] = useState(
    (currentPage - 1) * itemsPerPage
  );
  const [endIndex, setEndIndex] = useState(startIndex + itemsPerPage);

  let currentData = projects.slice(startIndex, endIndex);
  const data = projects.length;
  const [approveBtn, setApproveBtn] = useState(false);
  const [rejectBtn, setRejectBtn] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [showComponent, setShowComponent] = useState(false);
  const [projectIdList, setProjectIdList] = useState();
  const [userRole, setUserRole] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState(null);
  const [showStatus, setShowStatus] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [buItems, setBuItems] = useState([]);
  const [bUnit, setBUnit] = useState("");
  const [userAccess, setUserAccess] = useState([]);
  const [disable, setDisable] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(0); 
  const [lastClickedIndex, setLastClickedIndex] = useState(0); 
  const [yearFilter, setYearFilter] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [weekNumber, setWeekNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const firstDayOfYear = new Date(yearFilter, 0, 1);
  const firstDayOfWeek = addWeeks(startOfWeek(firstDayOfYear), weekNumber - 1);
  const lastDayOfWeek = endOfWeek(firstDayOfWeek);
  const startDate = format(firstDayOfWeek, "yyyy-MM-dd");
  const endDate = format(lastDayOfWeek, "yyyy-MM-dd");

  const componentRef = useRef();


  const weekHandler = (date) => {
    setSelectedDate(date);
    if (date) {
      const weekNum = date.week();
      setWeekNumber(weekNum);
      setYearFilter(date.year());
    }
    
  };


  useEffect(() => {
    let start = (currentPage - 1) * itemsPerPage;
    setStartIndex(start);
    setEndIndex(start + itemsPerPage);
  }, [currentPage]);

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const allData = [];
    let sno = 1;
   
    projects.forEach(project => {
        const projectData = {
            SNo: sno++,
            ProjectName: project.projectName,
            Description: project.description,
            RAG: project.rag,
            Dependency: project.dependency,
            Status: project.status,
             CreatedAt:project.createdAt
        };
   
        project.projectStagesEntity.forEach((stage, index) => {
            const rowData = { ...projectData };
            if (index === 0) {
                rowData.StageName = stage.stagesMasterEntity.stageName;
                rowData.ProjectStatus = stage.projectStatus;
                rowData.PercentCompleted = stage.percentCompleted;
                rowData.TargetDate = stage.targetDate;
                rowData.Remarks = stage.remarks;
            } else {
                rowData.SNo='';
                rowData.ProjectName = '';
                rowData.Description = '';
                rowData.RAG = '';
                rowData.Dependency = '';
                rowData.Status = '';
                rowData.CreatedAt='';
                rowData.StageName = stage.stagesMasterEntity.stageName;
                rowData.ProjectStatus = stage.projectStatus;
                rowData.PercentCompleted = stage.percentCompleted;
                rowData.TargetDate = stage.targetDate;
                rowData.Remarks = stage.remarks;
            }
   
            allData.push(rowData);
        });
    });
   
    const worksheet = XLSX.utils.json_to_sheet(allData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Projects Report");
   
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(blob, "projectStatusReport.xlsx");
  };
  
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: "Project Status Report",
  //   onAfterPrint: handleDownloadExcel,
  // });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      const role = decodedToken.role;
      const id = decodedToken.id;
      const userType = decodedToken.userType;
      setUserRole(role);
      setUserId(id);
      setUserType(userType);
    }

    if (userId && token !== null) {
      fetchUserDetails();
    }

    if (projectType === "" && token !== null) {
      fetchData();
    }

    if (token !== null) {
      fetchBunit();
    //  fetchMenuItems();
      
    }
  }, [projectType, startDate, endDate, userRole, token, userId]);
  const fetchData = async () => {
    try {
      setLoading(true);

      const shouldShowApprove = userAccess.some(
        (access) => access.approveAccess === true
      );

      if (
        userRole === "ADMINHEAD" ||
        userRole === "SUPERADMIN" ||
        userRole === "USER"||
        shouldShowApprove === true
      ) {
        const response = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/getProjectList`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;
        setProjects(data.map((item) => ({ ...item, isChecked: false })));
      } else {
        const response = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/userProjects`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;
        setProjects(data.map((item) => ({ ...item, isChecked: false })));
        setShowStatus(true);
      }
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const fetchProjectByWeek = async () => {
    const addFilter = {
      startDate: startDate,
      endDate: endDate,
      bunitId:bUnit,
      streamId: projectType,
    };
    const streamFilter = {
      startDate: startDate,
      endDate: endDate,
      bunitId:bUnit

    };
    const filterAll = {
      startDate: startDate,
      endDate: endDate,
    };
    try {
      if(bUnit==="" || projectType===""){
        alert("Please enter all the values!!!");
      }
      else{
      setShowStatus(false);
      if ( bUnit=== "" && projectType === "All") {
        const shouldShowApprove = userAccess.some(
          (access) => access.approveAccess === true
        );
        if (
          userRole === "ADMINHEAD" ||
          userRole === "SUPERADMIN" ||
          shouldShowApprove
        ) {
          const response = await axios.post(
            `${process.env.REACT_APP_DOMAIN}/api/getProjectListByWeek`,
            filterAll,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setProjects(response.data);
        } else {
          const response = await axios.post(
            `${process.env.REACT_APP_DOMAIN}/api/getProjectListByWeekStatus`,
            filterAll,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setProjects(response.data);
        }
      } else {
        
        const shouldShowApprove = userAccess.some(
          (access) => access.approveAccess === true
        );
        if(projectType === "All"){
          if (
            userRole === "ADMINHEAD" ||
            userRole === "SUPERADMIN" ||
            shouldShowApprove
          ) {
            const response = await axios.post(
              `${process.env.REACT_APP_DOMAIN}/api/getProjectListByBunit`,
              streamFilter,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setProjects(response.data);
            const shouldShowCheckbox = userAccess.some(
              (access) =>
                access.approveAccess === true && access.streamId === projectType
            );
            setShowCheckbox(shouldShowCheckbox);
          } else {
            const response = await axios.post(
              `${process.env.REACT_APP_DOMAIN}/api/getProjectListByBunitStatus`,
              streamFilter,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setProjects(response.data);
          }
        }
        else{
        if (
          userRole === "ADMINHEAD" ||
          userRole === "SUPERADMIN" ||
          shouldShowApprove
        ) {
          const response = await axios.post(
            `${process.env.REACT_APP_DOMAIN}/api/getProjectTypeDateFilter`,
            addFilter,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setProjects(response.data);
          const shouldShowCheckbox = userAccess.some(
            (access) =>
              access.approveAccess === true && access.streamId === projectType
          );
          setShowCheckbox(shouldShowCheckbox);
        } else {
          const response = await axios.post(
            `${process.env.REACT_APP_DOMAIN}/api/getProjectTypeDateStatusFilter`,
            addFilter,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setProjects(response.data);
        }
      }
      }
      
    }

    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  
  const fetchProjectByWeek1 = async () => {
    const streamFilter = {
      startDate: startDate,
      endDate: endDate,
      bunitId:clickedIndex
    };
    const filterAll = {
      startDate: startDate,
      endDate: endDate,
    };
    try {
        if ( userRole === "USER") {
          const response = await axios.post(
            `${process.env.REACT_APP_DOMAIN}/api/getProjectListByBunit`,
            streamFilter,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setProjects(response.data);
        } 
        if(userRole === "USER" && clickedIndex=== 0){
          const response = await axios.post(
            `${process.env.REACT_APP_DOMAIN}/api/getProjectListByWeek`,
            filterAll,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setProjects(response.data);
        }
      }     
     catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const clearFilter = async () => {
    try {
      setLoading(true);
      setProjects([]);
      setApproveBtn(false);
      setRejectBtn(false);
      setShowCheckbox(false);
      setProjectType("");
      setBUnit("");
      setWeekNumber("");
      setSelectedDate(null);
      const shouldShowApprove = userAccess.some(
        (access) => access.approveAccess === true
      );
      if (
        userRole === "ADMINHEAD" ||
        userRole === "SUPERADMIN" ||
        shouldShowApprove
      ) {
        const response = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/getProjectList`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProjects(response.data);
        setTimeout(()=>{
          setLoading(false)
        },2000)
      } else {
        const response = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/userProjects`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProjects(response.data);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const clearFilter1 = async () => {
    try {
      setLoading(true);
      setProjects([]);
        const response = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/getProjectList`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
    
        setProjects(response.data);
        setTimeout(()=>{
          setLoading(false)
        },2000)

    } catch (error) {
      console.error("Error fetching data", error);
    }
    setWeekNumber("");
    setSelectedDate(null);
    setClickedIndex(0);
    
  };
  const fetchMenuItems = async (value) => {
    try {
      if(value===0){
      const response = await axios.get(
        `${process.env.REACT_APP_DOMAIN}/api/getStreamList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMenuItems([...response.data]);}
      else{
        const response = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/getStreamByBu/${value}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setMenuItems([...response.data]);
        if((response.data).length===0){
          setDisable(true);
        }
        else{
          setDisable(false);
        }
      }
      }
     catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchBunit = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DOMAIN}/api/getBUnitList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setBuItems([...response.data]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DOMAIN}/api/getUserDetails/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserAccess([...response.data]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleProjectType = async (e) => {
    const value = e.target.value;
    setProjectType(value);
  };
  const handleBunit = async (e) => {
    const value = e.target.value;
    setBUnit(value);
   await fetchMenuItems(value);
  };
  const updateStatus = async (status) => {
    const projectIdList = updateData.map((idlist) => idlist.projectId);
    setProjectIdList(projectIdList);
    try {
      await axios.put(
        `${process.env.REACT_APP_DOMAIN}/api/updateApprovalStatus`,
        {
          status: status,
          projectIds: projectIdList,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (status === "Rejected") {
        setShowComponent(true);
        setRejectBtn(false);
      } else {
        setApproveBtn(false);
        // triggerMail();
        toast.success("Approved Successfully");
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
    fetchProjectByWeek();
  };

  // const triggerMail = () => {
  //   try {
  //     axios.get(`${process.env.REACT_APP_DOMAIN}/api/sendApprovalMail`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //   } catch (error) {
  //     console.error("Error fetching data", error);
  //   }
  // };

  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = projects.map((checkbox) =>
      checkbox.projectId === id
        ? { ...checkbox, isChecked: !checkbox.isChecked }
        : checkbox
    );
    setProjects(updatedCheckboxes);
    const updatedSelectedCheckboxes = updatedCheckboxes.filter(
      (checkbox) => checkbox.isChecked
    );
    setUpdateData(updatedSelectedCheckboxes);
    const isAnyCheckboxChecked = updatedCheckboxes.some(
      (checkbox) => checkbox.isChecked
    );
    if (isAnyCheckboxChecked) {
      setApproveBtn(true);
      setRejectBtn(true);
    } else {
      setApproveBtn(false);
      setRejectBtn(false);
    }

    setSelectAll(updatedCheckboxes.every((checkbox) => checkbox.isChecked));
  };

  const handleSelectAllChange = () => {
    const updatedCheckboxes = projects.map((checkbox) => ({
      ...checkbox,
      isChecked: !selectAll,
    }));
    setProjects(updatedCheckboxes);
    setUpdateData(updatedCheckboxes);
    setSelectAll(!selectAll);
    setApproveBtn(!selectAll);
    setRejectBtn(!selectAll);
  };
  const shouldRenderButton = userAccess.some(
    (access) => access.approveAccess === true && approveBtn
  );
  const shouldRenderRejectButton = userAccess.some(
    (access) => access.approveAccess === true && rejectBtn
  );
  const handleClick = (index) => {
    setClickedIndex(index);
    setLastClickedIndex(index); 
};

  return (
    <div className=" project-main">
      {  userRole==="USER"?(
      <div className="user-filter">
        <div className="weekpic">
          <div className="week-bod">
        <DatePicker
              picker="week"
              value={selectedDate}
              onChange={weekHandler}
              placeholder="Select Week"
              className="weekpick-1"
              size="large"
              allowClear={true}
              required
              disabled={disable}
            />
            </div>
            <div>
  <button
    className={`map-button ${clickedIndex === 0 ? 'clicked' : ''}`}
    style={{ backgroundColor: clickedIndex === 0 ? '#00ABD8' : '', marginRight: "10px", borderRadius: "15px" }}
    onClick={() => handleClick(0)}
  >
    All
  </button>
  {buItems.map((item, index) => (
    <button
      key={index}
      className={`map-button ${clickedIndex === item.id ? 'clicked' : ''}`}
      style={{ backgroundColor: clickedIndex === item.id ? '#00ABD8' : '', marginRight: "10px", borderRadius: "15px" }}
      onClick={() => handleClick(item.id)}
    >
      {item.bunit}
    </button>
  ))}
</div>

            
        </div>
        <div>
        <div className="filter-btn-new-1">
              <Button
                variant="contained"
                type="submit"
                onClick={clearFilter1}
                style={{
                  backgroundColor: "rgb(240, 240, 240)",
                  color: "black",
                  marginRight: "15px",
                  height: "28px",
                  width: "65px",
                }}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                type="submit"
                onClick={fetchProjectByWeek1}
                style={{
                  backgroundColor: "#0084A7",
                  color: "white",
                  height: "28px",
                  width: "65px",
                }}
                disabled={disable}
              >
                Apply
              </Button>
            </div>
        </div>

      </div>):null}
      <div className="add-project-new">
        <div>
          <h5>Project List</h5>
        </div>
        <div className="add-bt">
          {userRole === "SUPERADMIN" ? (
            <Link to="/BuMaster">
              <button className="btn-pro">BU</button>
            </Link>
          ) : (
            ""
          )}
          {userRole === "SUPERADMIN" ? (
            <Link to="/userPermission">
              <button className="btn-pro">Permission</button>
            </Link>
          ) : (
            ""
          )}
          {userRole === "ADMIN" || userRole === "SUPERADMIN" ? (
            <Link to="/stageMaster">
              <button className="btn-pro">+ Add Stage</button>
            </Link>
          ) : (
            ""
          )}
          {userRole === "ADMIN" || userRole === "SUPERADMIN" ? (
            <Link to="/form">
              <button className="btn-pro">+Add Project</button>
            </Link>
          ) : (
            ""
          )}
          {userRole === "ADMINHEAD" || userRole === "SUPERADMIN" || userRole === "USER"  ? (
          <button className="export" onClick={exportToExcel}>
            <img className="export-img" src={Export} alt="" />
          </button>
             ) : (
              ""
            )}
        </div>
      </div>
      {userRole!== "USER" ?(
      <div className="border">

      </div>):null}
      <div className="pro-table">
      {(userRole === "ADMINHEAD" ||userRole === "ADMIN" || userRole === "SUPERADMIN")? (
        <div className="filter-new">
          <div>
            <h5>Filter:</h5>
          </div>
          <div>
          <InputLabel style={{ color: "rgb(45, 45, 45)", fontSize: "14px" }}>
              Select Bunit
            </InputLabel>
            <FormControl >
              <Select
                className="project-stream"
                placeholder="Select Bunit"
                value={bUnit}
                style={{ fontSize: "10pt" }}
                onChange={handleBunit}
              >
                <MenuItem value={0}>All</MenuItem>
                {buItems.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.bunit}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <InputLabel style={{ color: "rgb(45, 45, 45)", fontSize: "14px" }}>
              Select Stream
            </InputLabel>
            <FormControl >
              <Select
                className="project-stream"
                placeholder="Select Stream"
                value={projectType}
                style={{ fontSize: "10pt" }}
                onChange={handleProjectType}
                disabled={disable}
              >
                <MenuItem value="All">All</MenuItem>
                {menuItems.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.streamName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DatePicker
              picker="week"
              value={selectedDate}
              onChange={weekHandler}
              placeholder="Select Week"
              className="weekpick"
              allowClear={true}
              size="large"
              required
              disabled={disable}
            />
            <div className="filter-btn-new">
              <Button
                variant="contained"
                type="submit"
                onClick={clearFilter}
                style={{
                  backgroundColor: "rgb(240, 240, 240)",
                  color: "black",
                  marginRight: "40px",
                  height: "28px",
                  width: "90px",
                }}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                type="submit"
                onClick={fetchProjectByWeek}
                style={{
                  backgroundColor: "#0084A7",
                  color: "white",
                  height: "28px",
                }}
                disabled={disable}
              >
                Apply
              </Button>
            </div>
          </div>
        </div>):null}
        <div>
          <div className="approve">
            {shouldRenderButton && (
              <button
                type="submit"
                className="approve-btn"
                onClick={() => updateStatus("Approved")}
              >
                Approve
              </button>
            )}
            {shouldRenderRejectButton && (
              <button
                type="submit"
                className="reject-btn"
                onClick={() => {
                  updateStatus("Rejected");
                }}
              >
                Reject
              </button>
            )}
          </div>
          <div className=" table-main" style={{ overflowX: "auto" }}>
            <table className="table table-striped" ref={componentRef}>
              <thead className="thead-dark">
                <tr>
                  {userAccess.map((access) =>
                    showCheckbox &&
                    access.approveAccess === true &&
                    projectType === access.streamId ? (
                      <th>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                        />
                      </th>
                    ) : (
                      ""
                    )
                  )}
                  <th>S.no</th>

                  <th>Project Name</th>

                  <th>RAG</th>
                { userRole==="USER"?
                  <th>Stream</th>:null
                  }

                  <th>Stage</th>

                  <th>Status</th>

                  <th>Completed(%)</th>

                  <th>Target Date</th>

                  <th>Remarks</th>

                  <th>Dependency</th>

                  <th>Status</th>
                </tr>
              </thead>
             
              <tbody>
              
                {currentData.map((project, index) => (
                  <tr key={project.projectId}>
                    {userAccess.map((access) =>
                      showCheckbox &&
                      access.approveAccess === true &&
                      projectType === access.streamId ? (
                        <td>
                          <input
                            type="checkbox"
                            checked={project.isChecked}
                            onChange={() =>
                              handleCheckboxChange(project.projectId)
                            }
                          />
                        </td>
                      ) : (
                        ""
                      )
                    )}
                    <td className="counterCell" style={{ width: "2%" }}></td>
                    <td>
                      <tr>{project.projectName}</tr>
                      <hr />
                      <tr style={{ fontSize: "10pt" }}>
                        {project.description}
                      </tr>
                    </td>
                    <td style={{ width: "3%" }}>
                      {project.rag === "red" ? (
                        <span>
                          <StatusIndicator dotSize={20} color="red" />
                        </span>
                      ) : (
                        ""
                      )}

                      {project.rag === "amber" ? (
                        <span>
                          <StatusIndicator dotSize={20} color="yellow" />
                        </span>
                      ) : (
                        ""
                      )}

                      {project.rag === "green" ? (
                        <span>
                          <StatusIndicator dotSize={20} color="green" />
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                    {userRole==="USER"?
                    <td style={{ fontSize: "10pt" }}>{project.streamName}</td>:null}
                    <td>
                      {project.projectStagesEntity.map((stage, stageIndex) => (
                        <tr style={{ height: "40px", fontSize: "14px" }}>
                          {stage.stagesMasterEntity.stages}
                        </tr>
                      ))}
                    </td>
                    <td style={{ width: "11%" }}>
                      {project.projectStagesEntity.map((stage, stageIndex) => (
                        <tr style={{ height: "40px", fontSize: "14px" }}>
                          {stage.projectStatus}
                        </tr>
                      ))}
                    </td>
                    <td style={{ width: "3%" }}>
                      {project.projectStagesEntity.map((stage, stageIndex) => (
                        <tr style={{ height: "40px", fontSize: "14px" }}>
                          {stage.percentCompleted}
                        </tr>
                      ))}
                    </td>

                    <td style={{ width: "9%" }}>
                      {project.projectStagesEntity.map((stage, stageIndex) => (
                        <tr style={{ height: "40px", fontSize: "14px" }}>
                          {stage.targetDate}
                        </tr>
                      ))}
                    </td>

                    <td className="remark-size">
                      {project.projectStagesEntity.map((stage, stageIndex) => (
                        <tr style={{ height: "40px" }}>{stage.remarks}</tr>
                      ))}
                    </td>
                    <td style={{ fontSize: "10pt" }}>{project.dependency}</td>
                    <td style={{ fontSize: "14px", width: "5%" }}>
                      {project.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {loading && <LoadingSpinner />}
            {showComponent && <ReviewPopup projectIds={projectIdList} />}
            <CustomPagination
              totalItems={data}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
            {/* <TableComponent data={projects} /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectList;
