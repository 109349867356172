// adalService.js
import AuthenticationContext from "adal-angular";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


function Navigation(){
  const navigate = useNavigate();
  navigate("/home");
}
const adalConfig = {
  clientId: "7e5041a8-6386-4c22-9f06-b9e54aefa34d",
  cacheLocation: "sessionStorage", // Choose where tokens should be cached
  popUp: false,
};

const authContext = new AuthenticationContext(adalConfig);
const aadService = {
  authContext,

  login() {
    this.authContext.login();
    // console.log(this.authContext ,"this.authContext.getCachedUser()"); we'll see tomorrow seri 
  },

  logout() {
    sessionStorage.clear();
    window.location.reload();
    this.authContext.logOut();
  },

  getValidate() {
    authContext.handleWindowCallback();

    // Check if the user is authenticated
    if (authContext.getCachedUser()) {
      // Authentication was successful
      const user = authContext.getCachedUser();
      localStorage.setItem("userEmail", user.userName);
      generateToken();
      return {
        stage: 1,
        msg: `Hi ${user.userName},Please click to go to your landing page.`,
      };
    } else if (authContext.getLoginError()) {
      // Authentication failed

      return { stage: 2, msg: `Authentication error` };
    } else {
      return { stage: 3, msg: `Please login your microsoft account` };
    }
  },

 
};


const generateToken = async () => {
  try{
      const response = await axios.post(
      `${process.env.REACT_APP_DOMAIN}/api/v1/auth/authenticate`,
      {
        email: localStorage.getItem("userEmail"),
      }
    );
    localStorage.setItem("token", response.data.token);
   
  }
  catch(error){
    toast.error("Invalid email");
  }
};

export default aadService;
