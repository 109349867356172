import React, { useEffect, useState } from 'react'
import "./UserPermission.css"
import {useNavigate,useParams} from "react-router-dom"
import { FormControl, InputLabel, MenuItem, Select, Checkbox, Button } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";

function UserPermission() {
  const [userDetails, setUserDetails] = useState([]);
    const [permissionDetails,setPermissionDetails] = useState([]);
    const {id}=useParams();
    const num=id
    const token = localStorage.getItem("token");
    const [menuItems, setMenuItems] = useState([]);
    const arrLen = (permissionDetails.length) === 0 ? 1 : permissionDetails.length;
    const initialArray = Array.from({ length: arrLen }, () => ({  userId:'' ,streamId:'' ,createAccess:false ,approveAccess:false}));
    const [permissionArray,setPermissionArray] = useState(initialArray);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    useEffect(()=>{
      if(num){
        axios.get(`${process.env.REACT_APP_DOMAIN}/api/superAdmin/getUsersListById/${num}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
      })
        .then((resp) => {
          setUserDetails([resp.data]);
        })
        .catch((error) => {
          console.error('Error:', error);
        });}
        if(num){
          permissionList(num);
       }
          axios.get(
            `${process.env.REACT_APP_DOMAIN}/api/getStreamList`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setMenuItems([...response.data]);
          })
          .catch((error) => {
            console.error('Error in the second request:', error);
          })    
  },[num,token]);
  const permissionList = (id) =>{
    axios.get(`${process.env.REACT_APP_DOMAIN}/api/superAdmin/getUserDetails/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
       setPermissionDetails(response.data);
      if((response.data).length!==0){
      const updatedArray = response.data.map((data,index)=>({...data,  uid : index + 1 }));
        setPermissionArray(updatedArray);
        }
        else{
          const updatedArray = permissionArray.map((data)=>({...data,  uid :  1 }));
        setPermissionArray(updatedArray)
        }
        
      })
      
      .catch((error) => {
        console.error('Error in the second request:', error);
      });
  }

  const navigate = useNavigate();

  const handleSubmit=(e)=>{
    e.preventDefault();
     const newStreams = permissionArray.slice(initialArray.length);
    const hasNullStream = newStreams.some((stream) => stream.streamId === "");

    if(hasNullStream){
      alert("Fields cannot be null");
    }
    else{
      axios.post(`${process.env.REACT_APP_DOMAIN}/api/superAdmin/addUserPermission`,permissionArray,{
        headers: {
          'Authorization': `Bearer ${token}`
      }
    })
      .then((data) => {
       
      })
      .catch((error)=>{
        console.error(error);
      });
      setTimeout(() => navigate("/userPermission"), 500);
      toast.success("Permission Editied Successfully");
    }
  }

    const handleAddStream= ()=>{
        setPermissionArray([...permissionArray,{ uid: permissionArray.length + 1, userId: num, streamId: '', createAccess: false, approveAccess: false }])
        
    }

    const handleRemoveStream= (index)=>{
        if(index >= initialArray.length){
        const updatedArray=[...permissionArray];
        updatedArray.splice(index,1);
        setPermissionArray(updatedArray);
    }
    }

    const handleCreateAccess = (e,uid) =>{
      const updatedCheckboxes = permissionArray.map((checkbox)=>
      checkbox.uid === uid ?{...checkbox, createAccess: e.target.checked} : checkbox
      );
      setPermissionArray(updatedCheckboxes);
      
    }

    const handleApproveAccess = (e,uid) =>{
      const updatedCheckboxes = permissionArray.map((checkbox)=>
      checkbox.uid === uid ?{...checkbox, approveAccess: e.target.checked} : checkbox
      );
      setPermissionArray(updatedCheckboxes);
    }

    const handlePop = (id) =>{
      
      setPopupVisible(true);     
      setDeleteId(id);

    }

    const handleDelete = () =>{
      axios.put(`${process.env.REACT_APP_DOMAIN}/api/superAdmin/deletePermission/${deleteId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
    })
      .then((resp) => {;
        permissionList(id);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
      setPopupVisible(false);
      
    }


    const handleProjectType = (e, index) => {
  const selectedProjectType = e.target.value;
  const updatedStreams = [...permissionArray];
  updatedStreams[index] = { ...updatedStreams[index], streamId: selectedProjectType, userId: num };
  setPermissionArray(updatedStreams);
};
  return (
    <div className='user-main'>
        <h5>User Permission</h5><hr></hr>
        <div  className='user-name'>
        {userDetails.map((userdetail, index) => (
  <div key={index} className='name-user'>
   <h5>{userdetail[0].name}</h5>
    <p>{userdetail[0].email}</p>
  </div>
))}
        </div>
        
         {permissionArray.map((stream, index
         ) => (
        <div key={stream.id} className='user-stream'>
          <div className='stream'>
            <div>
          <InputLabel style={{color:"rgb(45, 45, 45)",fontSize:"14px"}}>Stream</InputLabel>
            <FormControl>
              
              <Select className='user-details'
              value={permissionArray[index]?.streamId || ''}
              onChange={(e) => handleProjectType(e, index)}>
              {menuItems.map((item, index) => (
        <MenuItem key={index} value={item.id}>
          {item.streamName}
        </MenuItem>
              ))}
              </Select>
            </FormControl>
            </div>
            <div>
              <InputLabel style={{color:"rgb(45, 45, 45)",fontSize:"14px"}}>Create Access</InputLabel>
              <Checkbox checked={permissionArray[index].createAccess || ''}  onChange={(e)=>handleCreateAccess(e,stream.uid)}/>
            </div>

            <div>
              <InputLabel style={{color:"rgb(45, 45, 45)",fontSize:"14px"}}>Approve Access</InputLabel>
              <Checkbox checked={permissionArray[index].approveAccess || ''} onChange={(e)=>handleApproveAccess(e,stream.uid)}/>
            </div>
            
            
            {stream.id !== undefined  && (
            <div>
            <Button   variant="contained" style={{ backgroundColor: 'red', color: 'white',marginTop:"12px" }} onClick={()=>handlePop(stream.id)}>
                Delete
              </Button>
              </div>
            )}

            {index >= initialArray.length && (
              <Button onClick={() => handleRemoveStream(index)} className='remove-btn' variant="contained" style={{ backgroundColor: 'red', color: 'white' }}>
                X
              </Button>
            )}
          </div>        
        </div>        
      ))}
         <Button onClick={handleAddStream} className='add-btn' variant="contained" color="primary">+Add More
         </Button>
         <div className='sub-btn'>
         <Link to={`/userPermission`}>
         <Button  className='remove-btn' variant="contained" style={{ backgroundColor: 'lightGray', color: 'black',height:"35px",marginRight:"15px"}}>
                Back
              </Button>
              </Link>
              <Button  className='remove-btn' variant="contained" style={{ color: 'white',height:"35px" }} onClick={handleSubmit}>
                Submit
              </Button>
         </div>
         {isPopupVisible === true ? (
            <div className="del-popup">
              <p>Are you sure you want to delete?</p>
              <button onClick={handleDelete}>Yes</button>
              <button onClick={() => setPopupVisible(false)}>No</button>
            </div>
          ):""}
    </div>
    
    
  )
}



export default UserPermission
